var $ = jQuery;

/* Utility function Akash wrote below this line
================================================================*/

var form_ui_functionality = {
  creatingDropDown: function() {
    // this method is responsible for dropdown on signup page and my account page
    $('.dropdown').select2();

    $('.dropdown-nosearchbar').select2({
      minimumResultsForSearch: -1,
    });
  },
  validation: function() {
    // adding custom methods for forms validation
    $.validator.setDefaults({
      errorPlacement: function(error, element) {
        var language = $('html').attr('lang');
        var frenchErrorMessage = $(element).attr('data-french-err');
        var englishErrorMessage = $(element).attr('data-english-err');

        $(error).css('display', 'none');

        $(element).removeClass('error');

        $(element).parent().find('.main-err').addClass('show');
      },
    });

    // custom method for validation
    $.validator.addMethod(
      'valueNotEquals',
      function(value, element, arg) {
        return arg !== value;
      },
      'Value must not equal arg.'
    );

    // Method for password validation
    $.validator.addMethod(
      'strongPassword',
      function(value, element) {
        var conditions = $($('.c-form__signup-conditions')[1]).children();

        conditions = $(element)
          .parent()
          .next('.c-form__signup-conditions')
          .children();
        var condition_el;

        if (conditions.length > 0) {
          condition_el = $(conditions[0]);
        }

        var min_length = 4;
        var max_length = 20;

        var input_length = value.length;
        var met_length_requirement =
          input_length >= min_length && input_length <= max_length;

        if (condition_el !== undefined) {
          if (met_length_requirement) {
            // $(conditions[0]).addClass('valid');
            condition_el.addClass('valid');
          } else {
            // $(conditions[0]).removeClass('valid');
            condition_el.removeClass('valid');
          }
        }

        return met_length_requirement;
      },
      'Please Enter a valid password'
    );

    //email validation regex
    $.validator.addMethod('emailValidate', function(value, element) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(value);
    });

    $.validator.addMethod('usernameValidate', function(value, element) {
      var re = /^[a-zA-Z0-9]+$/;
      re = /^[a-zA-Z0-9]+([-_\.][a-zA-Z0-9]+)*[a-zA-Z0-9]$/;
      var result = re.test(value);

      return result;
    });

    $.validator.addMethod('testValidate', function(value, element) {
      return false;
    });

    var lang = $('html').attr('lang');

    if (lang === 'fr-FR') {
      jQuery.extend(jQuery.validator.messages, {
        required: 'Ceci est un champ obligatoire',
        emailValidate: 'Veuillez entrer une adresse courriel valide',
        minlength: 'Numéro invalide',
        number: 'Format de numéro non valide',
        strongPassword: 'S\'il vous plait entrez votre mot de passe',
        equalTo: 'La vérification de mot de passe ne correspond pas',
        iataNumber: 'Veuillez entrer un numéro IATA / ARC / TIDS valide',
        usernameValidate: 'Veuillez entrer un nom d\'utilisateur valide.',
      });
    } else {
      jQuery.extend(jQuery.validator.messages, {
        required: 'This field is required.',
        emailValidate: 'Please enter a valid email address',
        minlength: 'Invalid number',
        number: 'Invalid number format',
        strongPassword: 'Please enter your password',
        equalTo: 'Password does not match',
        iataNumber: 'Please enter a valid IATA/ARC/TIDS number',
        usernameValidate: 'Please enter a valid username',
      });
    }

    // validating login form
    $('#loginform').validate({
      onfocusout: function(element) {
        trigger_errors($(element), $(element).valid());
      },
      rules: {
        email: {
          required: true,
          emailValidate: true,
        },
        password: 'required',
        username: {
          required: true,
        },
      },
      submitHandler: function(form, form2) {
        var inputs = $(form).children();
        var username = $('#user_login').val();
        var password = $('#user_pass').val();
        var remember = $('#logged_in:checked').val();

        var details = {
          username: username,
          pwd: password,
          remember: remember,
        };

        // toggle_submit_btn($(form));
        toggle_submit_btn_and_loader(form, true);
        loginUser(details, form);
      },
    });

    // Forgot password email input form
    $('.c-forgot-password-form form').validate({
      // onfocusout: function(element) {
      //     $('.invalid-combination-login').css('display', 'none');
      //     trigger_errors($(element), $(element).valid());
      // },
      onkeyup: function(element) {
        $('.invalid-combination-login').css('display', 'none');
        trigger_errors($(element), $(element).valid());
        $('#user_login_reset_password ~ .validation_error.show').removeClass(
          'show'
        );
      },
      rules: {
        user_login_reset_password: {
          usernameValidate: true,
        },
      },
      submitHandler: function(form) {
        var reset_password_val = $('#user_login_reset_password').val();
        var details;

        toggle_submit_btn_and_loader(form, true);

        // Email is entered
        if (reset_password_val.indexOf('@') !== -1) {
          details = {
            email_address: reset_password_val,
          };
        } else {
          details = {
            username: reset_password_val,
          };
        }
        resetPasswordEmailCheck(details, form);
      },
    });

    // Forgot password password input form
    $('.c-reset-password-form form').validate({
      onkeyup: function(element) {
        trigger_errors($(element), $(element).valid());
      },
      rules: {
        password: {
          required: true,
          strongPassword: true,
        },
        passwordRetype: {
          equalTo: '#password',
        },
      },
      submitHandler: function(form) {
        toggle_submit_btn_and_loader(form, true);
        if ($('#user_email_rp').length) {
          var details = {
            user_email: $('#user_email_rp').val(),
            new_pwd: $('#password').val(),
          };
          resetPasswordSubmit(details, form);
        }

        if ($('#user_id_rp').length) {
          var details = {
            user_id: $('#user_id_rp').val(),
            new_pwd: $('#password').val(),
          };
          resetPasswordSubmit(details, form);
        }
      },
    });

    // validating signup step1
    $('.sign_up_form_step1 form').validate({
      onkeyup: function(element) {
        trigger_errors($(element), $(element).valid());
      },
      // onfocusout: false,
      rules: {
        field: {
          required: true,
          number: true,
          minlength: 8,
        },
        agency_name: {
          required: true,
        },
      },
      submitHandler: function(form) {
        var details = {
          iata_number: $(form).find('#iata_number').val(),
          agency_name: $(form).find('#agency_name').val(),
        };

        toggle_submit_btn_and_loader(form, true);

        console.log('verify agency');
        var response = verifyAgency(details, form);
      },
    });

    $('#agency_name').focus(function() {
      // validating iata number when user focus on Agency
      if ($('#iata_number').val() !== '') {
        $('#iata_number').valid();
      }
    });

    // validating signup step2
    $('.sign_up_form_step2 form').validate({
      onkeyup: function(element) {
        trigger_errors($(element), $(element).valid());
      },

      rules: {
        email: {
          required: true,
          emailValidate: true,
        },
        password: {
          required: true,
          strongPassword: true,
        },
        firstname: 'required',
        lastname: 'required',
        username: {
          required: true,
          usernameValidate: true,
        },
      },
      submitHandler: function(form) {
        var main_error = $(form)
          .find('.check_username_error.show')
          .removeClass('show');

        var details = {
          username: $('#signup_username').val(),
        };
        toggle_submit_btn_and_loader(form, true);
        checkUsernameExists(details, form);
      },
    });

    // validating signup step3
    $('.sign_up_form_step3 form').validate({
      onkeyup: function(element) {
        trigger_errors($(element), $(element).valid());
      },
      rules: {
        businessType: { valueNotEquals: '-1' },
        bookingType: { valueNotEquals: '-1' },
        homeAirport: { valueNotEquals: '-1' },
      },
      messages: {
        businessType: { valueNotEquals: 'Please select your Business Type' },
        bookingType: { valueNotEquals: 'Please select your Booking Type' },
        homeAirport: { valueNotEquals: 'Please select your Home Airport' },
      },
      submitHandler: function(form) {
        var details = {
          iata_number: $('#iata_number').val(),
          agency_name: $('#agency_name').val(),
          first_name: $('#firstname').val(),
          last_name: $('#lastname').val(),
          email_address: $('#signup_email').val(),
          username: $('#signup_username').val(),
          password: $('#signup_password').val(),
          business_type: $('#business_type').val(),
          booking_type: $('#booking_type').val(),
          home_airport: $('#home_airport').val(),
        };

        toggle_submit_btn_and_loader(form, true);
        signUpUser(details, form);
      },
    });

    // Validate forgot username form

    $('.c-forgot-username-form form').validate({
      // onfocusout: function(element) {
      onkeyup: function(element) {
        // $('.invalid-combination-login').css('display', 'none');
        trigger_errors($(element), $(element).valid());
        $('#user_login_forgot_username ~ .validation_error.show').removeClass(
          'show'
        );
      },
      rules: {
        user_login_forgot_username: {
          required: true,
          emailValidate: true,
        },
      },
      submitHandler: function(form) {
        var forgot_username_val = $('#user_login_forgot_username').val();
        var details;

        // Email is entered
        if (forgot_username_val.indexOf('@') !== -1) {
          details = {
            email_address: forgot_username_val,
          };
        } else {
          details = {
            username: forgot_username_val,
          };
        }
        toggle_submit_btn_and_loader(form, true);
        forgotUsernameEmailCheck(details, form);
      },
    });

    // Update password form for imported users
    $('.c-update-password-form form').validate({
      onkeyup: function(element) {
        trigger_errors($(element), $(element).valid());
      },
      rules: {
        new_password: {
          required: true,
          strongPassword: true,
        },
        new_passwordRetype: {
          equalTo: '#new_password',
        },
      },
      submitHandler: function(form) {
        var details = {
          user_id: $('#user_id_update_password').val(),
          new_password: $('#new_password').val(),
          update_password_nonce: $('#update_password_nonce').val(),
        };

        toggle_submit_btn_and_loader(form, true);
        updateImportedAccountPassword(details, form);
      },
    });
  },
};

form_ui_functionality.validation();

/* Utility function Bora wrote below this line
================================================================*/

// /var url = ajax_object.ajax_url;
var url = '/';
if (wp.ajax_url) {
  url = wp.ajax_url;
}

var user_data = {};

// $("#createUserSubmit").click(function() {
//     var details = {};
//     signUpUser(details);
// });

populateFormData();

$('#update_password_return_to_sign_in_btn').click(function() {
  var target = '.' + $(this).attr('data-target');
  var itself = '.' + $(this).attr('data-this');
  $(itself).css('display', 'none');
  $(target).css('display', 'block');
});

function verifyAgency(details, form) {
  var data = { action: 'verify_agency' };
  var data = $.extend(data, details);

  $.ajax({
    type: 'POST',
    url: url,
    data: data,
    success: function(response, status, request) {
      // res = response;
      toggle_submit_btn_and_loader(form, false);

      if (response.success) {
        // show signup step 2
        var button = $('.sign_up_form_step1 form').find('.changing_UI');
        var target = '.' + $(button[0]).attr('data-target');
        var itself = '.' + $(button[0]).attr('data-this');
        $(itself).css('display', 'none');
        $(target).css('display', 'block');

        if (response.iata_number) {
          user_data['iata_number'] = response.iata_number;
        }

        if (response.agency_details) {
          user_data['agency_details'] = response.agency_details;
        }
      } else {
        var language = $('html').attr('lang');

        $('.main-err.invalid-combination').addClass('show');

        // if (language === 'en-US') {
        //     $('.invalid-combination-en').css('display', 'block');
        // } else {
        //     $('.invalid-combination-fr').css('display', 'block');
        // }
      }
    },
    // async: false
  });

  return;
}

function signUpUser(details, form) {
  var data = { action: 'signup_user' };

  if (user_data['agency_details'] !== undefined) {
    details['agency_details'] = user_data['agency_details'];
  }

  var data = $.extend(data, details);

  $.ajax({
    type: 'POST',
    url: url,
    data: data,
    success: function(response, status, request) {
      toggle_submit_btn_and_loader(form, false);
      var res = response;
      var button = $(form).find('.changing_UI');
      var target = '.' + $(button[0]).attr('data-target');
      var itself = '.' + $(button[0]).attr('data-this');
      $(itself).css('display', 'none');
      $(target).css('display', 'block');
    },
    // async: false
  });
}

function populateFormData() {
  getACFSelectMultiple('acf_select_loader_multiple');
}

function getACFSelectMultiple(action) {
  var data = { action: action };
  var select_types = {
    business_type: '',
    booking_type: '',
    home_airport: '',
  };

  $.get(url, data, function(response, status, request) {
    if (response.success) {
      if (response.select && response.output_element_id) {
        var select_id = '#' + response.output_element_id;
        var select_el = $(select_id);

        if (select_el !== undefined) {
          select_el.html(response.select);
        }
      }

      $.each(select_types, function(i, item) {
        if (response[i]) {
          var select_id = '#' + response[i].output_element_id;
          var select_el = $(select_id);

          if (select_el !== undefined) {
            select_el.html(response[i].select);
          }
        }
      });

      form_ui_functionality.creatingDropDown();
    }
  });
}

function loginUser(details, form) {
  var data = { action: 'ajax_login' };
  data = $.extend(data, details);

  $.post(url, data, function(response, status, request) {
    if (response.success) {
      if (response.user_is_imported && response.password_needs_updating) {
        toggle_submit_btn_and_loader(form, false);
        $('#update_password_form_wrapper').css('display', 'block');

        if (response.user_id) {
          var user_id = response.user_id;

          if (user_id !== undefined) {
            var user_id_input =
              '<input type="hidden" name="user_id_update_password" value="' +
              user_id +
              '" id="user_id_update_password">';
            $('#update_password_form_wrapper form').append(user_id_input);
            $('.c-login').css('display', '').addClass('not-visible');
          }
        }
      } else {
        if (wp.login_redirect_url) {
          document.location.href = wp.login_redirect_url;
        }
      }
    } else {
      toggle_submit_btn_and_loader(form, false);
      var language = $('html').attr('lang');

      var main_error = $(
        '.c-login .main-err.invalid-combination-login'
      ).addClass('show');
    }
  });
}

function checkEmailExists(details, form) {
  var data = { action: 'check_email_exists' };
  data = $.extend(data, details);

  var active_lang = '';

  if (wp.active_lang) {
    active_lang = wp.active_lang;
  }

  var error_message = {
    en: 'Email already exists',
    fr: 'L\'adresse de courriel existe déjà.',
  };

  if (data !== undefined) {
    $.ajax({
      type: 'POST',
      url: url,
      data: data,
      success: function(response, status, request) {
        if (response.success) {
          var button = $(form).find('.changing_UI');
          var target = '.' + $(button[0]).attr('data-target');
          var itself = '.' + $(button[0]).attr('data-this');
          $(itself).css('display', 'none');
          $(target).css('display', 'block');
        } else {
          var error_message_string = error_message[active_lang];

          if ($('#check_email_exist').next('#signup_email-error').length) {
            $(form)
              .find('#signup_email-error')
              .css('display', 'inline-block')
              .text(error_message_string);
          } else {
            var insertLabel = $('#signup_email').after(
              '<label id="signup_email-error" class="error" gfbgf for="signup_email" style="display: inline-block;">' +
                error_message_string +
                '</label>'
            );
          }
        }
      },
      async: false,
    });
  }
}

function resetPasswordEmailCheck(details, form) {
  var data = { action: 'check_email_exists', success_condition: 'exists' };
  data = $.extend(data, details);

  if (data !== undefined) {
    $.post(url, data, function(response, status, request) {
      if (response.success) {
        var user_details = { user_login: '' };
        var user_login = '';
        var active_lang = '';
        var credential_type = 'email_address';

        if (wp.active_lang) {
          active_lang = wp.active_lang;
          user_details['active_lang'] = active_lang;
        }

        if (response.email_address) {
          user_login = response.email_address;
        } else if (response.username) {
          user_login = response.username;
          credential_type = 'username';
        }

        if (user_login !== undefined && user_login != '') {
          user_details['user_login'] = user_login;
          user_details['' + credential_type + ''];
          triggerResetPassword(user_details);
        }

        var button = $(form).find('.changing_UI');
        var target = '.' + $(button[0]).attr('data-target');
        var itself = '.' + $(button[0]).attr('data-this');
        $(itself).css('display', 'none');
        $(target).css('display', 'block');

        $('.new_password_form_wrapper').removeClass('hidden');
      } else {
        var reset_password_input = $(form).find('.validation_error');
        reset_password_input.addClass('show');
      }

      toggle_submit_btn_and_loader(form, false);
    });
  }
}

function resetPasswordSubmit(details, form) {
  var data = { action: 'ajax_reset_password' };
  data = $.extend(data, details);

  var home_url = '/';
  if (wp.home_url) {
    home_url = wp.home_url;
  }

  if (data != undefined) {
    $.post(url, data, function(response, status, request) {
      toggle_submit_btn_and_loader(form, false);

      if (response.success) {
        if (home_url != undefined) {
          document.location.href = home_url;
        } else {
          location.reload();
        }
      } else {
      }
    });
  }
}

function triggerResetPassword(details) {
  var form_url = '/';

  if (wp.reset_password_form_url) {
    form_url = wp.reset_password_form_url;
  }

  var data = { action: 'lostpassword' };
  data = $.extend(data, details);

  if (data !== undefined) {
    $.post(form_url, data, function(response, status, request) {});
  }
}

function forgotUsernameEmailCheck(details, form) {
  var data = { action: 'check_email_exists', success_condition: 'exists' };
  data = $.extend(data, details);

  if (data != undefined) {
    $.post(url, data, function(response, status, request) {
      var active_lang = '';

      if (wp.active_lang) {
        active_lang = wp.active_lang;
      }

      if (response.success) {
        var user_details = { email_address: '' };
        var user_login = details['email_address'];

        if (active_lang) {
          user_details['active_lang'] = active_lang;
        }

        if (user_login != undefined) {
          user_details['user_login'] = user_login;
          triggerForgotUsername(user_details, form);
        }
      } else {
        toggle_submit_btn_and_loader(form, false);
        var input = $(form).find('input#user_login_forgot_username');
        // trigger_errors(input, false);
        var validation_error_el = $(form).find('.validation_error');
        validation_error_el.addClass('show');
        // $(form).find('.main-err').css('display', 'block');
      }
    });
  }
}

function triggerForgotUsername(details, form) {
  var form_url = '/';

  if (wp.reset_password_form_url) {
    form_url = wp.reset_password_form_url;
  }

  var data = { action: 'lostusername' };
  data = { action: 'send_forgot_username_email' };
  data = $.extend(data, details);

  form_url = url;

  if (data != undefined) {
    $.post(form_url, data, function(response, status, request) {
      var button = $(form).find('.changing_UI');
      var target = '.' + $(button[0]).attr('data-target');
      var itself = '.' + $(button[0]).attr('data-this');
      $(itself).css('display', 'none');
      $(target).css('display', 'block');
      toggle_submit_btn_and_loader(form, false);
    });
  }
}

$('.btn-temporary-login').click(function() {
  $(this).prop('disabled', true);
  toggle_loader_with_form(
    $('.loader_wrapper'),
    $('.main_login_container'),
    true
  );
  $('#temporary_login_form').submit();
});

function checkUsernameExists(details, form) {
  var data = { action: 'check_username_exists' };
  data = $.extend(data, details);

  var active_lang = '';

  if (wp.active_lang) {
    active_lang = wp.active_lang;
  }

  $('#signup_step3_btn').unbind('click');

  if (data != undefined) {
    $.ajax({
      type: 'POST',
      url: url,
      data: data,
      success: function(response, status, request) {
        if (response.success) {
          var button = $(form).find('.changing_UI');
          var target = '.' + $(button[0]).attr('data-target');
          var itself = '.' + $(button[0]).attr('data-this');
          $(itself).css('display', 'none');
          $(target).css('display', 'block');
        } else {
          var main_error = $(form)
            .find('.check_username_error')
            .addClass('show');
        }
        toggle_submit_btn_and_loader(form, false);
      },
      // async: false
    });
  }
}

function updateImportedAccountPassword(details, form) {
  var data = { action: 'update_imported_user_password' };
  data = $.extend(data, details);

  var active_lang = '';

  if (wp.active_lang) {
    active_lang = wp.active_lang;
  }

  if (data != undefined) {
    $.ajax({
      type: 'POST',
      url: url,
      data: data,
      success: function(response, status, request) {
        toggle_submit_btn_and_loader(form, false);

        if (response.success) {
          var button = $(form).find('.changing_UI');
          var target = '.' + $(button[0]).attr('data-target');
          var itself = '.' + $(button[0]).attr('data-this');
          $(itself).css('display', 'none');
          $(target).css('display', 'block');
        }
      },
    });
  }
}

function trigger_errors(element, valid) {
  if (element) {
    var clear = false;
    var value = $(element).val();

    if (valid) {
      element.removeClass('has_errors');
      element.parent().find('p.error.show').removeClass('show');
    } else {
      if (value == '' && clear) {
        element.parent().find('p.error.show').removeClass('show');
      } else {
        element.addClass('has_errors');
      }
    }
  }
}

function afterImportedAccountPasswordUpdate() {
  var login_redirect_timeout_delay = 1750;
  var login_redirect_timeout = setTimeout(function() {
    clearTimeout(login_redirect_timeout);
    if (wp.login_redirect_url) {
      document.location.href = wp.login_redirect_url;
    }
  }, login_redirect_timeout_delay);
}

function toggle_submit_btn(form) {
  if (form && form != undefined) {
    var submit_btn = $(form).find('input.btn--navyblue[type="submit"]');
    if (submit_btn && submit_btn != undefined) {
      if (submit_btn.prop('disabled')) {
        submit_btn.prop('disabled', false);
      } else {
        submit_btn.prop('disabled', true);
      }
    }
  }
}

function toggle_submit_btn_and_loader(form, show) {
  toggle_submit_btn(form);
  toggle_loader_with_form($('.loader_wrapper'), form, show);
}
